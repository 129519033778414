<template>
  <form ref="importForm" @submit.prevent="handleSubmit">
    <!-- Organization -->
    <base-input
      :require="true"
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <organization-selector
        :organization="importation.organization.id"
        :filterable="true"
        :showAll="false"
        @organizationChanged="
          (organizationId) => {
            importation.organization.id = organizationId;
            onFormChanged();
          }
        "
        :disabled="!!importation.id"
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <template v-if="!hasDefaultName">
      <!-- Imports model -->
      <base-input
        :label="`${$t('IMPORTS.IMPORT_MODEL')} (*)`"
        :placeholder="`${$t('IMPORTS.CHOOSE_MODEL')}`"
        @change="onFormChanged()"
        v-model="importation.model"
      >
        <import-model-selector
          :models="models"
          :modelDefaultValue="importation.model"
          @importModelChanged="
            (model) => {
              importation.model = model.value;
              importation.name = `${model.value} ${formatDate()}`;
              templateFields = model.fields;
            }
          "
        />
      </base-input>
      <!-- Imports name -->
      <base-input
        :label="`${$t('IMPORTS.IMPORT_NAME')} (*)`"
        v-model="importation.name"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.name" />
    </template>

    <base-input
      :label="$t('COMMON.EXCERPT')"
      :placeholder="$t('COMMON.EXCERPT')"
    >
      <html-editor v-model="importation.excerpt" @change="onFormChanged()">
      </html-editor>
    </base-input>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <CsvUploader
      ref="uploadedFile"
      @csv-uploaded="handleUpload"
      @get-csv-file="getUploadFile"
    />
    <fieldset
      v-if="currentStep === 'second' || currentStep === 'third'"
      class="mt-3 border p-3 rounded-sm"
    >
      <p class="font-weight-bold">
        {{ $t("IMPORTS.IDENTIFIERS") }}
      </p>
      <p class="text-sm">
        {{ $t("IMPORTS.IDENTIFIERS_EXPLAIN") }}
      </p>
      <!-- Imports name -->
      <base-input
        :required="true"
        :label="`${$t('IMPORTS.MODEL_FIELD')} (*)`"
        v-model="importation.identifier.model_field"
        @change="onFormChanged()"
      >
        <el-select
          v-model="importation.identifier.model_field"
          :placeholder="`${$t('IMPORTS.MODEL_FIELD')}`"
          @change="onFormChanged"
        >
          <el-option
            v-for="(model, index) in templateFields"
            :key="index"
            :value="model.code"
            :label="model.code"
          />
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.identifier" />

      <base-input
        :required="true"
        :label="`${$t('IMPORTS.CSV_FIELD')} (*)`"
        v-model="importation.identifier.csv_field"
        @change="onFormChanged()"
      >
        <el-select
          v-model="importation.identifier.csv_field"
          :placeholder="`${$t('IMPORTS.CSV_FIELD')}`"
          @change="onFormChanged"
        >
          <el-option
            v-for="(model, index) in uploadedData.headers"
            :key="index"
            :value="model"
            :label="model"
          />
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.identifier" />
    </fieldset>

    <div class="mt-3">
      <ImportFirstStep
        :headers="uploadedData.headers"
        :rows="uploadedData.rows"
        v-if="currentStep === 'first'"
      />

      <ImportSecondStep
        @mapping-result="handleMapping"
        :model-headers="templateFields"
        :csv-headers="uploadedData.headers"
        v-if="currentStep === 'second'"
        ref="importSecondStepFormRef"
        :submitSecondStepForm="nextStep"
        :goToThirdStep="goToThirdStep"
        :defaultSelectedFields="mapping"
      />

      <ImportThirdStep
        @preview-data="handlePreviewData"
        :mapping="mapping"
        :templateFields="templateFields"
        :uploaded-data="uploadedData"
        :identifier="importation.identifier"
        v-if="currentStep === 'third'"
      />
    </div>

    <div class="my-4">
      <base-button
        v-if="currentStep !== 'first'"
        type="button"
        class="btn btn-sm btn-warning"
        @click.prevent="prevStep"
      >
        {{ $t("COMMON.PREV_STEP") }}
      </base-button>

      <base-button
        v-if="currentStep !== 'third' && Object.keys(uploadedData).length !== 0"
        type="button"
        class="btn btn-sm btn-default"
        @click.prevent="nextStep"
      >
        {{ $t("COMMON.NEXT_STEP") }}
      </base-button>

      <base-button
        v-if="currentStep !== 'first'"
        type="button"
        class="btn btn-sm btn-danger"
        :disabled="loading"
        @click.prevent="cancel"
      >
        {{ $t("COMMON.CANCEL") }}
      </base-button>

      <base-button
        v-if="currentStep === 'third'"
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          importation.id ? $t("IMPORTS.EDIT_IMPORT") : $t("IMPORTS.ADD_IMPORT")
        }}
      </base-button>
    </div>
  </form>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import ImportModelSelector from "@/components/ImportModelSelector.vue";
import CsvUploader from "@/components/CsvUploader.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ImportFirstStep from "@/views/Pages/AdminModule/ImportModule/Partials/Wizard/ImportFirstStep.vue";
import ImportSecondStep from "@/views/Pages/AdminModule/ImportModule/Partials/Wizard/ImportSecondStep.vue";
import ImportThirdStep from "@/views/Pages/AdminModule/ImportModule/Partials/Wizard/ImportThirdStep.vue";
import { Option, Select } from "element-ui";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseInput,
    ValidationError,
    OrganizationSelector,
    ImportModelSelector,
    CsvUploader,
    ImportFirstStep,
    ImportSecondStep,
    ImportThirdStep,
    HtmlEditor,
  },

  mixins: [formMixin],

  props: ["importData", "formErrors", "perPage"],

  data() {
    let importation = this.importData;
    if (this.$route.query) {
      importation = {
        ...importation,
        model: this.$route.query?.model,
        organization: {
          ...this.importData.organization,
          id:
            this.$route.query?.organization ?? this.importData.organization.id,
        },
      };
    }
    return {
      importation,
      loading: false,
      models: [],
      templateFields: [],
      hasDefaultName: false,
      uploadedData: {},
      uploadedFile: {},
      mapping: {},
      preview: {},
      currentStep: "first",
    };
  },

  mounted() {
    this.loadModels();
  },

  methods: {
    formatDate() {
      return moment(new Date()).format("DD-MM-YYYY HH:mm:ss:SSSS");
    },

    loadTemplateFields(model) {
      this.templateFields = _.sortBy(
        _.sortBy(model.fields ?? [], "name").reverse(),
        "required"
      ).reverse();
    },

    async loadModels() {
      try {
        await this.$store.dispatch("imports/models");
        this.models = this.$store.getters["imports/models"];
        await this.loadCurrentModelInformation();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async loadCurrentModelInformation() {
      const modelName = this.$route.query?.model;
      if (modelName) {
        this.importation.model = modelName;
        this.importation.name = `SYNC - ${this.formatDate()}`;
        this.hasDefaultName = true;
        this.templateFields =
          this.models.find((item) => item.value === modelName)?.fields ?? [];
        this.onFormChanged();
      }
    },

    async handleSubmit() {
      let linkedObject = null;
      if (this.$route.query.id) {
        linkedObject = {
          type: "suppliers",
          id: this.$route.query.id,
        };
      }
      this.importation = {
        ...this.importation,
        mapping: this.mapping,
        file: this.uploadedFile,
        linkedObject: linkedObject ?? undefined,
      };
      this.$emit("importSubmitted", this.importation);
    },

    handleUpload(data) {
      this.uploadedData = data;
    },

    getUploadFile(file) {
      this.uploadedFile = file;
    },

    handleMapping(result) {
      this.mapping = result;
    },

    handlePreviewData(data) {
      this.preview = data;
    },
    onSubmitSecondStepForm() {
      this.$refs.importSecondStepFormRef.$refs.stepTwoSubmitButton.click();
    },

    goToThirdStep() {
      this.currentStep = "third";
    },

    nextStep() {
      if (this.currentStep === "first") {
        this.currentStep = "second";
      } else if (this.currentStep === "second") {
        this.onSubmitSecondStepForm();
      }
    },

    prevStep() {
      if (this.currentStep === "second") {
        this.currentStep = "first";
      } else if (this.currentStep === "third") {
        this.currentStep = "second";
      }
    },

    cancel() {
      this.importation = { ...this.importData };
      this.uploadedData = {};
      this.uploadedFile = {};
      this.templateFields = [];
      this.currentStep = "first";
      this.$refs.importForm.reset();
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },
};
</script>
